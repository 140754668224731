import 'digix-ui/ativos/sass/temas/papel-zero.scss';
import ContainerDeMensagensFlutuantes from '@bit/digix.digixui.mensagens-flutuantes';

import { routesConfig } from 'config';
import { RouterCreator } from 'router';

export function AppContext(): JSX.Element {
  return (
    <>
      <ContainerDeMensagensFlutuantes />
      <RouterCreator
        routesConfig={routesConfig}
        redirectPath="/"
        fallbackRedirectPath="/"
      />
    </>
  );
}
